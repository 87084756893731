import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { EventService } from 'src/app/core/services/event.service';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

    constructor(
        private apiService: ApiService,
        private modalService: NgbModal,
        public formBuilder: FormBuilder,
        private httpClient: HttpClient,
        private eventService: EventService,
        private calendar: NgbCalendar,
        private _lightbox: Lightbox,
        private _lightboxConfig: LightboxConfig,
        public translateService: TranslateService
        // private modalService: NzModalService,
        // private notification: NzNotificationService
    ) {
        _lightboxConfig.centerVertically = true
        _lightboxConfig.wrapAround = true
        _lightboxConfig.showImageNumberLabel = true
        _lightboxConfig.disableScrolling = true


    }

    mapRating = { 1: 'Không tốt', 2: 'Trung bình', 3: 'Tốt', 4: 'Rất tốt' }

    listEmp = []
    formUpdate = {
        note: '',
        status: 0,
        id: 0,
        type_id: 0
    }
    breadCrumbItems
    // type$ = this.apiService.getList('incident-type')
    location$ = this.apiService.getList('users/employees')

    searchTerm
    listData = []
    listDataAssign = []

    pageSize = 10
    pageIndex = 1
    totalCount = 0
    tableName = 'feedbacks'
    // tableName = 'bookings'

    submitted: boolean
    formData: FormGroup
    dataOption
    selectedBooking
    selectedBookingTracks
    note
    _albums = []
    params = ''

    get form() {
        return this.formData.controls
    }

    ngOnInit() {
        this.breadCrumbItems = [{ label: 'PAGE.HOME' }, { label: 'Báo cáo', active: true }];
        this.getDataunit()
        this.fetchData()
        this.getEmployees()

    }

    zoom = 8
    center: google.maps.LatLngLiteral = { lat: 13.786377, lng: 100.608755 };
    exportExel() {
        this.eventService.setLoading(true);
    
        const headers = new HttpHeaders({
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
    
        const url = 'https://api-sratingv2.tomotek.vn/api/v1/export-excel?unitCode=' + this.filter.location_id;
    
        this.httpClient.get(url, { headers, responseType: 'blob' }).subscribe({
            next: (res: Blob) => {
                this.eventService.setLoading(false);
    
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Bao_cao.xlsx';
                link.click();
            },
            error: (e) => {
                console.error('Lỗi API:', e);
                this.eventService.setLoading(false);
                alert('Đã xảy ra lỗi khi gọi API.');
            }
        });
    }


    open(i) {
        this._lightbox.open(this._albums, i);
    }

    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }
    getEmployees() {
        // this.eventService.setLoading(true)
        this.apiService.getList('users/employees' + '?location_id=' + this.filter.location_id).subscribe({
            next: res => {
                this.listEmp = res
                // this.eventService.setLoading(false)

            },
            error: e => {
                alert('lỗi')
                // this.eventService.setLoading(false)

            }
        })
    }
    getDataunit(params = '') {
        // let params =''
        // if(this.keyword){
        //     params ='&name='+this.keyword
        // }
        // if(this.selectedStation&& this.selectedStation.length >0){
        //     this.selectedStation.forEach(d => {
        //         params += `&station_id=${d}`
        //     })
        // }
        params = '&order=updated_at&direction=desc'
        this.eventService.setLoading(true)
        this.listData = []
        this.apiService.getList2('locations').subscribe({
            next: (res) => {
                const data = res['data']
                // const meta = res['total']
                // this.totalCount= res.total
                this.dataOption = data.map(item => ({
                    value: item['id'],
                    label: item['org_name'],
                }));

                this.eventService.setLoading(false)
                this.totalCount = res['total']
            }
        })
    }
    openModalImage(data) {
        // console.log(data)
    }

    onChangePage(e) {
        this.pageIndex = e
        this.fetchData()
    }

    searching = false

    getEmp(id) {
        if (this.listEmp && id) {
            let find = this.listEmp.find(i => i.id == id)
            if (find) {
                return find.full_name
            }
            return id
        } else return id
    }

    clearFilter() {
        this.selected = ''
        this.params = ''
        this.filter.user_id = -1
        this.filter.level = -1
        this.filter.type_id = -1
        // this.filter.status = -1
        // this.filter.location_id = -1
        this.filter.start_date = 0
        this.filter.end_date = 0
        this.pageIndex = 1
        this.searching = false
        this.fetchData()
    }

    submitFilter() {
        this.params = ''
        Object.keys(this.filter).forEach(key => {
            // console.log(key+ '-'+ this.filter[key])
            if (this.filter[key] >= 0) {
                this.params += `&${key}=${this.filter[key]}`
            }
        })
        if (this.params) {
            this.pageIndex = 1
            this.searching = true
            this.fetchData()
        }
    }

    fetchData() {
        let params = ''
        // if(this.keyword){
        //     params ='&name='+this.keyword
        // }
        // if(this.selectedStation&& this.selectedStation.length >0){
        //     this.selectedStation.forEach(d => {
        //         params += `&station_id=${d}`
        //     })
        // }

        this.eventService.setLoading(true)
        this.listData = []
        this.apiService.getList2(this.tableName + `?page=${this.pageIndex}&limit=${this.pageSize}${this.params}`).subscribe({
            next: res => {
                const data = res['data']
                // const meta = res['total']
                // this.totalCount= res.total
                this.listData = data
                // this.note = [this.listData.map(item => ({
                //     note: item.note.split(';')
                //     }))
                // ]
                this.listData = this.listData.map(item => {
                    let temp = item.note.split(';');
                    return { ...item, note: temp };
                });
                this.eventService.setLoading(false)
                this.totalCount = res['total']
            }
        })
    }


    openModal(content: any) {
        // this.apiService.successmsg()
        this.formData = this.makeForm()
        this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
    }
    dataForm
    onEdit(data, content) {
        console.log(data)
        this.dataForm = data
        this.formData = this.makeForm()
        this.modalService.open(content, { centered: true, scrollable: true, size: 'lg' });
    }

    onImgError(event) {
        event.target.src = './assets/images/img-error.jpg'
    }

    indexPrice = 0
    onDetailBooking(data, content) {
        // if(data.id){
        //     this.eventService.setLoading(true)
        //     this.apiService.getList(`incident/${data.id}`).subscribe({
        //         next: res => {
        //             this.selectedBooking = res
        //             this._albums = [...this.selectedBooking.images].map(i => {
        //                 const src = environment.imageHost + i.url;
        //                 const classification = i.classification;
        //                 const caption = i.filename;
        //                 const thumb = src;
        //                 return { src, caption, thumb, classification }
        //             })
        //             this.selectedBooking['imageClasstification'] = this._albums.find(i => i.classification)
        //             // console.log(this.selectedBooking)
        //             this.formUpdate.note = res.note
        //             this.formUpdate.status = res.status
        //             this.formUpdate.type_id = res.type_id
        //             this.formUpdate.id = res.id
        //             this.modalService.open(content, { centered: true, scrollable: true, windowClass: 'my-class' });
        //             this.selectedBooking['lat'] = 0
        //             this.selectedBooking['lng'] = 0
        //             if(this.selectedBooking.location_other && this.selectedBooking.location_other.latitude && this.selectedBooking.location_other.longitude){
        //                 this.selectedBooking['lat'] = this.selectedBooking.location_other.latitude
        //                 this.selectedBooking['lng'] = this.selectedBooking.location_other.longitude
        //             }else if(this.selectedBooking.location_detail && this.selectedBooking.location_detail.lat && this.selectedBooking.location_detail.lng){
        //                 this.selectedBooking['lat'] = this.selectedBooking.location_detail.lat
        //                 this.selectedBooking['lng'] = this.selectedBooking.location_detail.lng
        //             }
        //             this.center = this.selectedBooking
        //             this.eventService.setLoading(false)
        //         },
        //         error: e => {
        //             this.eventService.setLoading(false)
        //         }
        //     })
        // }

    }

    updateFormNote() {
        if (this.formUpdate.id) {
            this.eventService.setLoading(true)
            this.apiService.editItem(this.tableName, this.formUpdate.id, this.formUpdate).subscribe({
                next: res => {
                    this.fetchData()

                    this.apiService.showToast(this.translateService.instant('Thành công'), this.translateService.instant('FORM.SuccessMessage'), 'success')
                    this.eventService.setLoading(false)
                    this.modalService.dismissAll()
                },
                error: e => {
                    // console.log(e)
                    this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.ErrorMessage'), 'error')
                    this.eventService.setLoading(false)
                }
            })

        }
    }

    getDetail(id) {

    }



    makeForm(d?) {
        let data = d ? d : <any>{}
        return this.formBuilder.group({
            id: [data.id],
            name: [data.name, [Validators.required]],
            description: [data.description],
            price: [data.price || 0, [Validators.required]],
            status: [data.status || false],
            image_url: [data.image_url, [Validators.required]],
            media_id: [data.media_id],
            parent_id: [data.parent_id || 0],
            currency: [data.currency || 'VND'],
        })
    }

    saveData() {
        this.submitted = true
        if (this.formData.valid) {
            const value = this.formData.value
            this.eventService.setLoading(true)
            if (value.id) {
                this.apiService.editItem(this.tableName, value.id, value).subscribe({
                    next: res => {
                        this.fetchData()
                        let a = this.translateService.instant('PAGE.FORM.Error')
                        this.apiService.showToast(a, ('PAGE.FORM.SuccessMessage'), 'success')
                        this.eventService.setLoading(false)
                    },
                    error: e => {
                        this.apiService.showToast('Lỗi', 'Có lỗi xảy ra', 'error')
                        this.eventService.setLoading(false)
                    }
                })
            } else {
                this.apiService.addItem(this.tableName, value).subscribe({
                    next: res => {
                        this.fetchData()
                        this.apiService.showToast('', 'Thêm mới thông tin thành công', 'success')
                        this.eventService.setLoading(false)
                        this.modalService.dismissAll()
                    },
                    error: e => {
                        this.apiService.showToast('Lỗi', 'Có lỗi xảy ra', 'error')
                        this.eventService.setLoading(false)
                    }
                })
            }

        } else {
            // console.log('invalid', this.formData)
            Object.values(this.formData.controls).forEach(control => {
                if (control.status == 'INVALID') {
                    control.markAsDirty();
                    control.updateValueAndValidity({ onlySelf: true });
                }
            });
        }
    }

    filter = {
        user_id: -1,
        level: -1,
        type_id: -1,
        location_id: '',
        // status: -1,
        start_date: -1,
        end_date: -1
    }

    hoveredDate: NgbDate;
    fromNGDate: NgbDate;
    toNGDate: NgbDate;

    model: NgbDateStruct;
    date: { year: number, month: number };
    // Select2 Dropdown
    selectValue: string[];

    hidden: boolean = true
    selected: any;
    @Input() fromDate: Date;
    @Input() toDate: Date;
    @Output() dateRangeSelected: EventEmitter<{}> = new EventEmitter();
    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromNGDate = date;
            this.fromDate = new Date(date.year, date.month - 1, date.day);
            this.selected = '';
            this.filter.start_date = 0;
            this.filter.end_date = 0;

        } else if (this.fromDate && !this.toDate && (date.after(this.fromNGDate) || date.equals(this.fromNGDate))) {
            this.toNGDate = date;
            this.toDate = new Date(date.year, date.month - 1, date.day);
            this.hidden = true;
            this.selected = moment(this.fromDate).format('DD/MM/YYYY') + '-' + moment(this.toDate).format('DD/MM/YYYY');

            // Sử dụng trực tiếp moment để tính toán
            this.filter.start_date = moment(this.fromDate).startOf('day').unix();
            this.filter.end_date = moment(this.toDate).endOf('day').unix();

            this.dateRangeSelected.emit({ fromDate: this.fromDate, toDate: this.toDate });

            // Reset các giá trị sau khi đã chọn ngày xong
            this.fromDate = null;
            this.toDate = null;
            this.fromNGDate = null;
            this.toNGDate = null;

        } else {
            this.fromNGDate = date;
            this.fromDate = new Date(date.year, date.month - 1, date.day);
            this.selected = '';
            this.filter.start_date = 0;
            this.filter.end_date = 0;
        }
    }

    /**
    * Is hovered over date
    * @param date date obj
    */
    isHovered(date: NgbDate) {
        return this.fromNGDate && !this.toNGDate && this.hoveredDate && date.after(this.fromNGDate) && date.before(this.hoveredDate);
    }

    /**
     * @param date date obj
     */
    isInside(date: NgbDate) {
        return date.after(this.fromNGDate) && date.before(this.toNGDate);
    }

    /**
     * @param date date obj
     */
    isRange(date: NgbDate) {
        return date.equals(this.fromNGDate) || date.equals(this.toNGDate) || this.isInside(date) || this.isHovered(date);
    }

    /**
     * Select the today
     */
    selectToday() {
        this.model = this.calendar.getToday();
    }

    clearDate() {
        this.selected = ''
        this.filter.start_date = 0
        this.filter.end_date = 0
    }

}
